<template>
  <div class="alarm-box">
    <el-table
      :data="filterAlarmList"
      ref="tableRef"
      height="8rem"
      style="width: 90%; margin: 0 auto"
    >
      <el-table-column type="index" width="50"/>
      <el-table-column prop="alarmTime" label="报警时间" width="200" />
      <el-table-column prop="alarmStatus" label="预警信息">
        <template #default="scope">
          <span
            class="back-color"
            :class="[filterAlarmInfoTextColor(scope.row)]"
            >{{ filterAlarmInfoText(scope.row) }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="alarmRopeNum" label="钢丝绳号" width="150" />
      <el-table-column prop="alarmSite" label="损伤位置" />
      <!-- <el-table-column prop="alarmRopeNum" label="楼层位">
        <template #default="scope">
          <span
            class="back-color"
            :class="[filterDamageLevelBackColor(scope.row)]"
            >{{ filterDamageLevelText(scope.row) }}</span
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="alarmRopeNum" label="楼层位" />
    </el-table>
  </div>
</template>

<script>
import { GetSingleAlarmLog } from "@/common/api/index/api.js";
import { nextTick, onMounted, watch, reactive } from "vue";
export default {
  name: "",
  setup(props) {
    const ruleForm = reactive({
      id: 0,
      last_id:0,
      count:500,
      type:0
    });
    const alarmListAll = reactive([]);
    const filterAlarmList = reactive([]);

    const httpGetSingleAlarmLog = async (ruleForm) => {
      const res = await GetSingleAlarmLog(ruleForm);
      const alarmList = res.data.alarmlog;
      alarmListAll.push(...alarmList);

      const filterAlarm = alarmList.filter((v) => v[2] > 1);
      if (filterAlarm.length > 0) {
        const filterAlarmArr = [];
        for (let i = 0; i < filterAlarm.length; i++) {
          const tempObj = {};
          tempObj.id = filterAlarm[i][0];
          tempObj.alarmTime = filterAlarm[i][1];
          tempObj.alarmStatus = filterAlarm[i][2];
          tempObj.alarmSite = filterAlarm[i][3].toFixed(2)+"m";
          tempObj.alarmFloor = filterAlarm[i][4];
          tempObj.alarmRopeNum = filterAlarm[i][5];
          filterAlarmArr.push(tempObj);
        }
        filterAlarmList.push(...filterAlarmArr);
      }
    };

    const filterAlarmInfoText = (row) => {
      switch (row.alarmStatus) {
        case 1:
          return "掉线";
        case 2:
          return "预警";
        case 3:
          return "报警";
        case 4:
          return "故障";
        default:
          break;
      }
    };

    const filterAlarmInfoTextColor = (row) => {
      switch (row.alarmStatus) {
        case 1:
          return "offline";
        case 2:
          return "yujing";
        case 3:
          return "baojing";
        case 4:
          return "guzhang";
        default:
          break;
      }
    };

    watch(
      () => props.id,
      (id) => {
        ruleForm.id = id;

        httpGetSingleAlarmLog(ruleForm);
      },
      { deep: true, immediate: true }
    );

    onMounted(() => {
      nextTick(() => {});
    });

    return {
      filterAlarmList,
      alarmListAll,
      filterAlarmInfoText,
      filterAlarmInfoTextColor,
    };
  },

  data() {
    return {};
  },

  props: {
    id: [Number, String],
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.alarm-box {
  color: #fff;
  & :deep .el-table__cell{
    text-align: center;
  }
}
</style>