<template>
  <div class="home">
    <div class="logo-box">
      <div class="logo">
        <img src="/logo_base.png" alt="" />
      </div>
      <div class="report-num">
        <p>报告编号：{{ basicReportInfo.reportNum }}</p>
      </div>
    </div>
    <div class="title-box">
      <p>{{langObj.report.mrt_report}}</p>
    </div>
    <div class="report-info">
      <div class="inner">
        <div class="item">
          <p class="label">{{langObj.report.test_equipment}}：</p>
          <p class="text">{{ basicReportInfo.testDevice }}</p>
        </div>
        <div class="item">
          <p class="label">{{langObj.report.detection_object}}：</p>
          <p class="text">{{ basicReportInfo.testObj }}</p>
        </div>
        <div class="item">
          <p class="label">{{langObj.report.rope_specs}}：</p>
          <p class="text">{{ basicReportInfo.ropeSpecs }}</p>
        </div>
        <div class="item">
          <p class="label">{{langObj.report.report_date}}：</p>
          <p class="text">{{ basicReportInfo.reprotDate }}</p>
        </div>
      </div>
    </div>

    <div class="report-info report-man">
      <div class="inner">
        <div class="item">
          <p class="label">{{langObj.report.inspector}}：</p>
          <p class="text">{{ basicReportInfo.inspectionMan }}</p>
        </div>
        <div class="item">
          <p class="label">{{langObj.report.reviewer}}：</p>
          <p class="text">{{ basicReportInfo.checkMan }}</p>
        </div>
        <div class="item">
          <p class="label">{{langObj.report.approver}}：</p>
          <p class="text">{{ basicReportInfo.approveMan }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "",
  setup() {
    const store = useStore();

    const langObj = computed(() => {
      return store.state.lang.list;
    });

    return {
      langObj,
    };
  },
  data() {
    return {};
  },

  mounted() {},

  props: {
    basicReportInfo: Object,
    wireRopeData: Object,
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 1000px;
  background-color: #fff;
  height: 1000px;
  color: #333;

  .logo-box {
    padding: 50px 30px 30px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      img {
        width: 160px;
      }
    }
    .report-num {
      color: #333;
      font-size: 16px;
    }
  }
  .title-box {
    margin-top: 60px;
    text-align: center;
    p {
      font-size: 30px;
      font-weight: 600;
      font-family: "宋体";
    }
  }
  .report-info {
    margin-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner {
      width: 70%;
      .item {
        display: flex;
        justify-content: flex-start;
        font-size: 16px;
        margin-bottom: 40px;
        p.label {
          width: 150px;
          text-align: right;
        }
        p.text {
          width: 500px;
          text-align: center;
          border-bottom: 1px solid #333;
          padding-bottom: 5px;
        }
      }
    }
  }
  .report-man {
    margin-top: 60px;
  }
}
</style>