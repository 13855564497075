<template>
  <div class="report-container">
    <div class="report-box">
      <div class="left">
        <div class="date-box box-shadow">
          <div class="title">
            <p>{{ langObj.report.report_week }}</p>
          </div>
          <ul v-if="Object.keys(dateEveryDay).length > 0">
            <li
              v-for="(item, index) in dateEveryDay"
              :key="index"
              @click="handleClickDate(item, index)"
              :class="{ active: index == clickIndex }"
            >
              <span
                ><el-icon><Tickets /></el-icon
              ></span>
              <span> {{ item }}</span>
            </li>
          </ul>
        </div>

        <div class="run-box box-shadow">
          <p>总里程：{{ wireRopeData.currentLengthAll }}km</p>
          <p>总时长：{{ wireRopeData.currentTimeAll }}h</p>
        </div>
        <div class="parameter-box box-shadow">
          <div class="title">
            <p>{{ langObj.report.parame_option }}</p>
          </div>
          <div class="item">
            <el-form-item :label="langObj.report.filter_level">
              <el-select
                v-model="ruleFormParam.filter_level"
                :placeholder="langObj.report.filter_level"
              >
                <el-option :label="langObj.report.no_filter" :value="0" />
                <el-option :label="langObj.detail.Slight" :value="1" />
                <el-option :label="langObj.detail.Medium" :value="2" />
                <el-option :label="langObj.detail.Serious" :value="3" />
                <el-option :label="langObj.detail.Severe" :value="4" />
                <el-option :label="langObj.detail.Over_limit" :value="5" />
              </el-select>
            </el-form-item>
          </div>

          <div class="item">
            <el-form-item :label="langObj.report.value_filter">
              <el-input
                v-model="ruleFormParam.filter_value"
                :placeholder="langObj.report.value_filter"
              />
            </el-form-item>
          </div>

          <div class="item">
            <el-form-item :label="langObj.report.waveform_length">
              <el-input
                v-model="ruleFormParam.pageLength"
                :placeholder="langObj.report.waveform_length"
                show-word-limit
                @blur="filterRuleParamPageLength"
              />
            </el-form-item>
          </div>
          <div class="item">
            <el-form-item :label="langObj.report.sensor_waveform">
              <el-checkbox
                v-model="ruleFormParam.isCuver"
                label=""
                size="large"
              />
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="basic-info box-shadow">
          <div class="title">
            <p>{{ langObj.report.basic_info }}</p>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.report_date">
                <el-input
                  v-model="basicReportInfo.reprotDate"
                  :placeholder="langObj.report.report_date"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.report_number">
                <el-input
                  v-model="basicReportInfo.reportNum"
                  :placeholder="langObj.report.report_number"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.detection_object">
                <el-input
                  v-model="basicReportInfo.testObj"
                  :placeholder="langObj.report.detection_object"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.inspector">
                <el-input
                  v-model="basicReportInfo.inspectionMan"
                  :placeholder="langObj.report.inspector"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.reviewer">
                <el-input
                  v-model="basicReportInfo.checkMan"
                  :placeholder="langObj.report.reviewer"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.approver">
                <el-input
                  v-model="basicReportInfo.approveMan"
                  :placeholder="langObj.report.approver"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item1">
              <el-form-item :label="langObj.report.report_title">
                <el-input
                  v-model="basicReportInfo.reprotTitle"
                  :placeholder="langObj.report.report_title"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item1">
              <el-form-item :label="langObj.report.test_equipment">
                <el-input
                  v-model="basicReportInfo.testDevice"
                  :placeholder="langObj.report.test_equipment"
                />
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="wire-rope-info basic-info box-shadow">
          <div class="title">
            <p>{{ langObj.report.rope_info }}</p>
          </div>
          <div class="form-box">
            <!-- <div class="form-item">
              <el-form-item label="检测对象">
                <el-input
                  v-model="wireRopeData.testObj"
                  placeholder="检测对象"
                />
              </el-form-item>
            </div> -->
            <div class="form-item">
              <el-form-item :label="langObj.report.rope_length + '(m)'">
                <el-input
                  v-model="wireRopeData.ropeLenth"
                  :placeholder="langObj.report.rope_length + '(m)'"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.Specs_models">
                <el-input
                  v-model="wireRopeData.ropeSpecific"
                  :placeholder="langObj.report.Specs_models"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.rope_diameter + '(mm)'">
                <el-input
                  v-model="wireRopeData.ropeDm"
                  :placeholder="langObj.report.rope_diameter + '(mm)'"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.rope_diameter">
                <el-input
                  v-model="wireRopeData.ropeManuf"
                  :placeholder="langObj.report.rope_diameter"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.rope_number">
                <el-input
                  v-model="wireRopeData.ropeNum"
                  :placeholder="langObj.report.rope_number"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.application">
                <el-input
                  v-model="wireRopeData.ropePurpose"
                  :placeholder="langObj.report.application"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.cross_section">
                <el-input
                  v-model="wireRopeData.ropeEmArea"
                  :placeholder="langObj.report.cross_section"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.material">
                <el-input
                  v-model="wireRopeData.ropeMaterials"
                  :placeholder="langObj.report.material"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.surface_treatment">
                <el-input
                  v-model="wireRopeData.ropeMaterialbm"
                  :placeholder="langObj.report.surface_treatment"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.core_steel">
                <el-input
                  v-model="wireRopeData.ropeMaterialx"
                  :placeholder="langObj.report.core_steel"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.twisting_method">
                <el-input
                  v-model="wireRopeData.ropeTwistWay"
                  :placeholder="langObj.report.twisting_method"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.breaking_load">
                <el-input
                  v-model="wireRopeData.ropeFknFact"
                  :placeholder="langObj.report.breaking_load"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.lubricant">
                <el-input
                  v-model="wireRopeData.ropeLubricant"
                  :placeholder="langObj.report.lubricant"
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-box">
            <div class="form-item">
              <el-form-item :label="langObj.report.install_date">
                <el-input
                  v-model="wireRopeData.ropeUsedate"
                  :placeholder="langObj.report.install_date"
                />
              </el-form-item>
            </div>
            <div class="form-item">
              <el-form-item :label="langObj.report.scrap_limit">
                <el-input
                  v-model="wireRopeData.scrapLimit"
                  :placeholder="langObj.report.scrap_limit"
                />
              </el-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="report-pdf">
      <reportPdf
        :exportPdf="exportPdf"
        :basicReportInfo="basicReportInfo"
        :wireRopeData="wireRopeData"
        :flawDailyDataClick="flawDailyDataClick"
        :ruleFormParam="ruleFormParam"
        :id="ruleForm.id"
        :clickReport="clickReport"
      ></reportPdf>
    </div>
  </div>
</template>

<script setup>
import {
  nextTick,
  onMounted,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
} from "vue";
import {
  GetStationFlawDaily,
  GetRopeInfo,
  GetStationInfo,
  GetStationFlawData,
} from "@/common/api/index/api.js";

import utils from "../../../common/utils";
import reportPdf from "@/components/detail/report/reportPdf";
import { useStore } from "vuex";

const props = defineProps({
  id: [Number, String],
  statistics: Object,
  allRunLength: Number,
  allRunTime: Number,
  allWeekRunLength: Number,
  allWeekRunTime: Number,
  clickReport: Boolean,
});

const ruleForm = reactive({
  id: 0,
  sdate: "",
  edate: "",
  sort: 0,
});

const dateEveryDay = reactive([]); //日期区间
const flawDailyData = reactive([]); // 日统计数据信息

let flawDailyDataClickObj = reactive({
  flawDailyDataClick: {}, //当前点击的数据信息
});

const wireRopeInfoObj = reactive({
  wireRopeData: {
    //testObj: "", //检测对象
    ropeLenth: "", //钢丝绳长度
    ropeSpecific: "", //规格型号
    ropeDm: "", //钢丝绳直径
    ropeManuf: "", // 制造商
    ropeNum: "", //钢丝绳数量
    ropePurpose: "", //用途
    ropeEmArea: "", //横截面积
    ropeMaterials: "", //钢丝绳材质
    ropeMaterialbm: "", //表面处理
    ropeMaterialx: "", //绳芯材质
    ropeTwistWay: "", //捻制方式
    ropeFknFact: "", //破断负载
    ropeLubricant: "", //润滑剂
    ropeUsedate: "", //安装日期
    scrapLimit: "", // 报废上限
    currentLengthAll: "", //截止当前日期总里程
    currentTimeAll: "", // 截止当前日期总时长
  },
  wireRopeInfo: {},
  todayTestLength: 0,
});

//参数过滤
const ruleFormParam = reactive({
  filter_level: 0,
  filter_value: 0.0,
  pageLength: 100,
  isCuver: false,
});

//报告基本信息
const basicReportInfo = reactive({
  reprotDate: "", //报告日期
  reportNum: "", //报告编号
  reprotTitle: "钢丝绳MRT检测报告", //报告标题
  testDevice: "TCK.W钢丝绳在线实时自动监测系统", //检测设备
  testObj: "河南三建1号塔吊",
  ropeSpecs: "",
  inspectionMan: "", //验检人
  checkMan: "", //审核人
  approveMan: "", //批准人
  testLength: 0, //检测长度
});

const { wireRopeData } = toRefs(wireRopeInfoObj);
const { flawDailyDataClick } = toRefs(flawDailyDataClickObj);

let clickIndex = ref(0);
let exportPdf = ref(false);

const store = useStore();

const langObj = computed(() => {
  return store.state.lang.list;
});

//历史日统计数据
const httpGetStationFlawDaily = async (ruleForm) => {
  const res = await GetStationFlawDaily(ruleForm);
  const flawDaily = res.data.data;
  flawDailyData.length = 0;
  const flawDailyArr = [];
  for (let i = 0; i < flawDaily.length; i++) {
    const tempObj = {};
    tempObj.date = flawDaily[i][0];
    tempObj.testLength = flawDaily[i][1];
    tempObj.runNum = flawDaily[i][2];
    tempObj.runTime = flawDaily[i][3];
    tempObj.runLength = flawDaily[i][4];
    tempObj.testNum = flawDaily[i][5];
    tempObj.realTestNum = flawDaily[i][6];
    tempObj.countFlaw = flawDaily[i][7];
    flawDailyArr.push(tempObj);
  }
  flawDailyData.push(...flawDailyArr);
};

const httpGetRopeInfo = async () => {
  const data = {};
  data.id = props.id;
  const res = await GetRopeInfo(data);
  wireRopeInfoObj.wireRopeInfo = res.data;
  wireRopeInfoObj.wireRopeData.ropeLenth = res.data.rope_len;
  wireRopeInfoObj.wireRopeData.ropeSpecific = res.data.rope_specific;
  wireRopeInfoObj.wireRopeData.ropeDm = res.data.rope_dm;
  wireRopeInfoObj.wireRopeData.ropeManuf = res.data.rope_manuf;
  wireRopeInfoObj.wireRopeData.ropeNum = res.data.rope_cnt;
  wireRopeInfoObj.wireRopeData.ropePurpose = res.data.rope_purpose;
  wireRopeInfoObj.wireRopeData.ropeEmArea = res.data.rope_em_area;
  wireRopeInfoObj.wireRopeData.ropeMaterials = res.data.rope_material_s;
  wireRopeInfoObj.wireRopeData.ropeMaterialbm = res.data.rope_material_bm;
  wireRopeInfoObj.wireRopeData.ropeMaterialx = res.data.rope_material_x;
  wireRopeInfoObj.wireRopeData.ropeTwistWay = res.data.rope_twist_way;
  wireRopeInfoObj.wireRopeData.ropeFknFact = res.data.rope_fkn_fact;
  wireRopeInfoObj.wireRopeData.ropeLubricant = res.data.rope_lubricant;
  wireRopeInfoObj.wireRopeData.ropeUsedate = res.data.rope_usedate;
};

const httpGetStationFlawData = async () => {
  const data = {
    id: props.id,
    filter_value: "",
    filter_level: 0,
    sort: 0,
    reverse: 0,
  };
  const res = await GetStationFlawData(data);

  //如果返回 start_pos和end_pos
  if (Object.hasOwnProperty.call(res.data, "start_pos")) {
    let start_pos = res.data.start_pos;
    let end_pos = res.data.end_pos;
    wireRopeInfoObj.todayTestLength = Math.abs(end_pos - start_pos); //当天的检测长度
  } else {
    wireRopeInfoObj.todayTestLength = 0;
  }
};

const handleClickDate = (currentDay, index) => {
  clickIndex.value = index;

  // const clickData = flawDailyData.find((v) => v.date == currentDay);
  const clickData = filterDayByTestMaxLength(currentDay); //过滤服务端出现多个日期

  flawDailyDataClickObj.flawDailyDataClick =
    clickData == undefined ? {} : clickData;
  basicReportInfo.reportNum = "WR" + utils.formatDateTime();
  basicReportInfo.reprotDate = currentDay;

  // 如果是获取当天日期报告，就从todayTestLength获取
  basicReportInfo.testLength =
    clickData == undefined
      ? wireRopeInfoObj.todayTestLength
      : clickData.testLength;

  //如果历史记录没有选择的日期 检测长度就为0
  if (utils.formatDate() != currentDay && clickData == undefined) {
    basicReportInfo.testLength = 0;
  }

  computedCurretDayAllLengthTime(currentDay);
};

// 先以日均检测长度最大值获取currentDay日期
const filterDayByTestMaxLength = (currentDay) => {
  const dayList = flawDailyData.filter((v) => v.date == currentDay);
  const sortDayList = dayList.sort((a, b) => b.testLength - a.testLength);
  return sortDayList[0];
};

//计算截止当前日期的总时长和总里程
const computedCurretDayAllLengthTime = (currentDay) => {
  let gtRunlength = 0;
  let gtRunTime = 0;
  for (let i = 0; i < flawDailyData.length; i++) {
    if (flawDailyData[i].date > currentDay) {
      gtRunlength += flawDailyData[i].runLength;
      gtRunTime += flawDailyData[i].runTime;
    }
  }

  //判断是否是显示今天
  if (props.clickReport) {
    wireRopeInfoObj.wireRopeData.currentLengthAll =
      props.allWeekRunLength.toFixed(2);
    wireRopeInfoObj.wireRopeData.currentTimeAll =
      props.allWeekRunTime.toFixed(1);
  } else {
    wireRopeInfoObj.wireRopeData.currentLengthAll = (
      props.allRunLength - gtRunlength
    ).toFixed(2);

    wireRopeInfoObj.wireRopeData.currentTimeAll = (
      props.allRunTime - gtRunTime
    ).toFixed(1);
  }
};

const httpGetStationInfo = async () => {
  const res = await GetStationInfo();
  const stationList = res.data.info;
  const currentStation = stationList.find((v) => v[0] == props.id);
  basicReportInfo.testObj =
    currentStation[4] + currentStation[3] + currentStation[2];
  basicReportInfo.ropeSpecs = currentStation[8];
};

const filterRuleParamPageLength = (e) => {
  if (e.target.value < 10) {
    ruleFormParam.pageLength = 10;
  }
};

watch(
  () => props.id,
  () => {},
  { deep: true, immediate: true }
);

watch(
  () => [props.statistics, props.clickReport],
  async ([statistics]) => {
    let sdate = statistics.startDate;
    let edate = statistics.endDate;
    if (Object.keys(statistics).length > 0 && !props.clickReport) {
      sdate = statistics.startDate;
      edate = statistics.endDate;
    } else {
      const currentDay = utils.formatDate();
      sdate = currentDay;
      edate = currentDay;
    }
    let everyDay = utils.formatEveryDay(sdate, edate);
    dateEveryDay.length = 0;
    dateEveryDay.push(...everyDay.reverse());
    ruleForm.id = props.id;
    ruleForm.sdate = sdate;
    ruleForm.edate = edate;
    await httpGetStationFlawDaily(ruleForm);
    httpGetRopeInfo();
    await httpGetStationFlawData();
    handleClickDate(dateEveryDay[0], 0);
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  nextTick(() => {
    httpGetStationInfo();
  });
});
</script>

<style lang="scss" scoped>
.report-container {
  .report-box {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0;
    .left {
      width: 25%;
      // background-color: #F0F0F0;
      // height: 7.5rem;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .date-box {
        padding: 0.0625rem;
        .title {
          padding: 0.125rem 0;
          p {
            font-size: 0.2rem;
            font-weight: 600;
            border-bottom: 1px dashed #021e33;
            padding-bottom: 0.1rem;
          }
        }
        ul {
          text-align: center;

          li {
            padding: 0.0875rem;
            font-size: 15px;
            cursor: pointer;
            span {
              margin-right: 0.1rem;
            }
          }
          li:hover {
            color: #021e33;
          }
          li.active {
            background-color: #021e33;
            color: #fff;
          }
        }
      }

      .run-box {
        margin-top: 0.375rem;
        padding: 0.0625rem;
        p {
          font-size: 15px;
          padding: 0.1rem;
        }
      }

      .parameter-box {
        padding: 0.0625rem;
        margin-top: 0.375rem;
        .title {
          padding: 0.125rem 0;
          p {
            font-size: 0.2rem;
            font-weight: 600;
            border-bottom: 1px dashed #021e33;
            padding-bottom: 0.1rem;
          }
        }
        .item {
          & :deep .el-checkbox.el-checkbox--large {
            height: auto;
          }
        }
      }
    }

    .right {
      width: 70%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      // height: 400px;
      .basic-info {
        padding: 0.0625rem;
        .title {
          padding: 0.125rem 0;
          p {
            font-size: 0.2rem;
            font-weight: 600;
            border-bottom: 1px dashed #021e33;
            padding-bottom: 0.1rem;
          }
        }
        .form-box {
          display: flex;
          justify-content: space-between;
          padding: 0 5px;
          .form-item {
            width: 45%;
          }
          .form-item1 {
            width: 100%;
          }
          & :deep .el-form-item__label {
            width: 140px;
            text-align: center;
          }
        }
      }

      .wire-rope-info {
        margin-top: 0.375rem;
      }
    }

    .box-shadow {
      width: 90%;
      box-shadow: 0 0 5px #f0f0f0;
      background-color: #f0f0f0;
    }
  }
}
</style>
